<template>
  <div class="formation">
    <liste_evenement/>
    
  </div>
</template>

<script>
// @ is an alias to /src

import liste_evenement from '../components/l_evenement.vue'

export default {
  name: 'Evenementliste',
  components: {
    liste_evenement,
  }
}
</script>
